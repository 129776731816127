div.dialog {
    background-color: transparent !important;
    background-color: transparent !important;
    box-shadow: none !important;
    overflow: visible !important;
    margin-top: calc(var(--height-header) + var(--gutter-6)) !important;
    margin-left: var(--gutter-6) !important;
    margin-right: var(--gutter-6) !important;
    width: calc(100% - calc(var(--gutter-6) * 2)) !important;
    max-width: 800px !important;
    display: inline-flex !important;
    gap: var(--gutter-2) !important;

    > div {
        flex: 1;
    }

    > button {
        position: static;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form {
    flex: 1;
    display: flex;
    gap: 5px;
    position: relative;
    align-items: center;
    width: 100%;
    background-color: #fff;
    box-shadow: var(--box-shadow-4);
    border-radius: 27px;
    padding: 0 var(--gutter-2) 0 var(--gutter-3);

    .icon {
        margin-right: var(--gutter-2);
    }

    input[type="text"] {
        width: 100%;
        height: 54px;
        padding: 0;
        font-size: .9rem;
        border: none;
    }
    
    .submit {
        margin-left: var(--gutter-1);
        min-width: 70px;
        max-width: 70px;
    }

    .additional-button {
        min-width: 34px;
        height: 34px;
    }

    button:focus,
    button:active {
        box-shadow: none !important;
    }
}

.close {
    box-shadow: var(--box-shadow-4) !important;

    &:not(:hover) {
        background-color: #fff !important;
    }    

    &:hover {
        background-color: var(--color-background) !important;
    }
    
}

.info {
    cursor: pointer;
    color: var(--color-font);
    transition: color 100ms ease-in-out;
    font-size: 1.2rem;

    &:hover {
        color: var(--color-info);
    }
}

div.info-dialog {
    margin-top: calc(var(--height-header) + var(--gutter-3));
    margin-left: var(--gutter-6);
    margin-right: var(--gutter-6);
    --modal-size: calc(100% - calc(var(--gutter-6) * 2)) !important;
    max-width: 450px !important;

    p {
        text-align: left;
        margin: 0 0 var(--gutter-2);
    }

    ul {
        margin: 0;
        padding-left: 25px;
    }

    > div > div:nth-child(2) {
        padding-top: var(--gutter-5) !important;
        padding-bottom: var(--gutter-5) !important;
    }
}