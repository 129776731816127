div.Toastify__toast {
    border-radius: 10px !important;
    padding: 20px 35px 20px 20px !important;
}

.Toastify__close-button {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}