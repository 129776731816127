@import "assets/css/variables.scss";

:root {
    --height-customer-exists: 30px;
    --height-header-combined: calc(var(--height-header) + var(--height-customer-exists));
    --height-shell-combined: calc(var(--height-shell) + var(--height-customer-exists));
}

html {
    overflow-X: hidden;
}

.App {
    &--customer-exists {
        padding-top: calc(var(--height-header) + var(--height-customer-exists)) !important;
    }
}

.container {
    @media #{$mq-upto-l} {
        max-width: 9999px !important;
        padding-left: 30px !important;
        padding-right: 30px !important;
    }
    @media #{$mq-upto-s} {
        padding-left: 15px !important;
        padding-right: 15px !important;
    }
}

.toast-content-encompass {
    margin: -14px -28px -14px -14px;
    padding: 14px 28px 14px 14px;
    text-align: left;
}

.kiosk-indicator {
    .slidepanel--open & {
        display: none !important;
    }
}

.skeleton {
    position: relative;
    background-color: #00000010;
    border-radius: 5px;
    overflow: hidden;
}

.skeleton:after {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    transform: translateX(-200%);
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: skeletonloading 1s infinite linear;
}

@keyframes skeletonloading {
    100% {
        transform: translateX(200%);
    }
}